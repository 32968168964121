.main-footer {
  background-color: $blue;
  height: 45px;
  @media(max-width: 768px) {
    height: auto;
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 45px;
    padding: 0 60px;
    @media(max-width: 768px) {
      flex-wrap: wrap;
      height: auto;
      padding: 0;
      padding-top: 20px;
      padding-bottom: 20px;
    }
    .address {
      color: #FFF;
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 1px;
      text-transform: uppercase;
      @media(max-width: 768px) {
        line-height: 1.3;
        text-align: center;
        margin-bottom: 20px;
      }
    }
    .logos {
      display: flex;
      align-items: center;
      @media(max-width: 768px) {
        justify-content: center;
        width: 100%;
      }
      span {
        color: #FFF;
        font-size: 12px;
        letter-spacing: 1px;
        //padding-right: 10px;
      }
      img {
        padding-left: 10px;
      }
    }
  }
}
