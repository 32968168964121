/*! Colors list */
$colors: (
  // base: #000,
  // blue: (
  //     default: #00F,
  //     dark:    #005
  // )
);
$blue: #0D375B;

// |> Returns a color code from its key
@function color($keys...) {
  $map: $colors;
  @each $key in $keys {
    $map: map-get($map, $key);
  }
  @return $map;
}


/*! Font families list */
$fonts: (
  // main: "Roboto",
  // title: "Lato",
  // content: "Fira"
);

// |> Returns a font family name from its category
@function font($font-category) {
  @return map-get($fonts, $font-category);
}

/*! Font sizes list */
$size: (
  // title:    4.5rem,
  // headline: 1.6rem,
  // content: (
  //     small:  1.4rem,
  //     medium: 2.4rem,
  //     large:  3.0rem
  // )
);

// |> Returns a font size from its category
@function size($categories...) {
  $map: $size;
  @each $category in $categories {
    $map: map-get($map, $category);
  }
  @return $map;
}
