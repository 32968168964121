/*! Main imports. */
@import '../vendor/normalize/normalize.css';
@import '../vendor/bootstrap/bootstrap-grid.min.css';
@import '../vendor/fancybox/jquery.fancybox.min.css';
@import '../vendor/slick/slick.css';

/*! Local imports. */
@import 'reset';
@import 'vars';

/*! Body */
body {
  font-family: 'Open Sans';
  background-color: #F5F6F9;

  overflow-x: hidden;
}

/*! Pages */
// @import 'pages/[page_name]'
@import 'home.scss';

@import 'elements/header.scss';
@import 'elements/footer.scss';
