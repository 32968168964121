.main-header {
  background-color: $blue;
  height: 70px;
  position: relative;
  z-index: 50;
  .logo {
    text-align: right;
    position: relative;
    a {
      position: relative;
      top: 10px;
      right: 25px;
    }
    &:before {
      content: '';
      background-color: #FFF;
      background-image: linear-gradient(to bottom, #FFF , #CED4D8);
      position: absolute;
      top: 0;
      right: 0;
      bottom: -20px;
      left: -300%;
      transform: skew(25deg);
    }
  }
  .iso {
    padding-top: 18px;
    padding-left: 20px;
  }
}
