.page-home {
  #slider {
    border-bottom: 4px solid #FFD072;
    height: 700px;
    position: relative;
    z-index: 1;

    // Mobile
    @media (max-width: 1279px) {
      height: auto;
      padding: 0 0 80px;
    }

    .slider {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;

      // Mobile
      @media (max-width: 1279px) {
        position: relative;
        width: 100%;
        height: 600px;
      }
      @media (max-width: 1023px) {
        height: 450px;
      }
      @media (max-width: 767px) {
        height: 300px;
      }

      .slider-wrapper {
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 2;
        .slide {
          background-repeat: no-repeat;
          background-position: center center;
          background-size: cover;
          width: 100%;
          height: 700px;

          // Mobile
          @media (max-width: 1279px) {
            background-position: center center !important;
            height: 600px;
          }
          @media (max-width: 1023px) {
            height: 450px;
          }
          @media (max-width: 767px) {
            height: 300px;
          }
        }
      }

      .arrows {
        position: absolute;
        bottom: 110px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 3;
        background-color: white;
        padding: 20px 35px;
        display: flex;
        align-items: center;
        pointer-eventes: none;

        // Mobile
        @media (max-width: 1279px) {
          bottom: 0;
          padding: 10px 15px;
        }

        // Arrow
        .arrow {
          position: relative;
          pointer-eventes: initial;
          padding: 7px 20px;
          cursor: pointer;
          border: 1px solid #f3f3f3;
          transition: all 500ms;

          &-left {
            margin-right: 10px;
          }

          &:hover {
            border-color: #2e6ab2;
          }
        }
      }
    }

    // Container
    .container {
      pointer-events: none;
    }

    .flex-wrapper {
      display: flex;
      justify-content: flex-end;
      height: 700px;
      position: relative;
      z-index: 2;

      // Mobile
      @media (max-width: 1279px) {
        height: auto;
        justify-content: center;
      }
      .right {
        position: relative;
        width: 30%;
        pointer-events: initial;

        // Mobile
        @media (max-width: 1279px) {
          width: auto;
        }
        &::before {
          content: '';
          background-color: #DCDDDE;
          position: absolute;
          top: 0;
          right: -300%;
          bottom: 0;
          left: 0;
          transform: skew(-20deg);

          // Mobile
          @media (max-width: 1279px) {
            display: none;
          }
        }
        .block {
          position: relative;
          z-index: 2;
          .info {
            color: $blue;
            font-size: 14px;
            line-height: 130%;
            text-transform: uppercase;
            padding: 0 27px;
            padding-top: 20px;
          }
          .form {
            background: #FFF;
            position: relative;
            width: 300px;
            margin-top: 150px;

            // Mobile
            @media (max-width: 1279px) {
              margin-top: 40px;
            }
            &-headline {
              color: #FFF;
              font-size: 16px;
              font-weight: 100;
              letter-spacing: 1px;
              line-height: 1.6;
              text-align: center;
              text-transform: uppercase;
              background-color: #205AA6;
              padding: 9px;
              @media (max-width: 768px) {
                font-size: 14px;
              }
            }
            &-confirmation {
              background-color: white;
              display: flex;
              position: absolute;
              top: -1px;
              right: 0;
              bottom: -5px;
              left: 0;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              padding: 0 10px;
              z-index: 2;
              visibility: hidden;
              clip-path: polygon(0 50%, 100% 50%, 100% 50%, 0 50%);
              transition: all 500ms;
               > span {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 100%;
              }
              p {
                color: #00960B;
                font-size: 18px;
                line-height: 130%;
                margin-bottom: 10px;
                &.tiny {
                  font-size: 14px;
                }
              }
              .info {
                small {
                  color: #666;
                  font-size: 12px;
                  line-height: 140%;
                  a {
                    color: #C0090B;
                  }
                }
              }
            }
            form {
              position: relative;
              margin-top: 20px;
              z-index: 1;
              .form-field {
                position: relative;
                padding: 0 28px;
                &:not(:nth-child(4)) {
                  margin-bottom: 10px;
                }
                @media (max-width: 768px) {
                  transition: all 300ms;
                }
                input {
                  color: $blue;
                  font-size: 14px;
                  border: none;
                  border-bottom: 2px solid $blue;
                  width: 100%;
                  padding: 12px 10px;
                  &::placeholder {
                    color: $blue;
                  }
                }
                &::before {
                  content: 'Por favor, preencha o campo corretamente.';
                  color: $blue;
                  font-size: 12px;
                  white-space: nowrap;
                  background-color: #FFD072;
                  border-radius: 3px 0 0 3px;
                  position: absolute;
                  top: 50%;
                  right: calc(100% - 10px);
                  padding: 5px 20px 5px 6px;
                  transform: translate(-30px, -50%);
                  opacity: 0;
                  clip-path: polygon(0 0, calc(100% - 10px) 0, 100% 50%, calc(100% - 10px) 100%, 0 100%);
                  transition: all 500ms cubic-bezier(.3, 0, 0, 1);
                  transition-property: transform, opacity;
                  @media (max-width: 768px) {
                    border-radius: 0;
                    top: calc(100% - 1px);
                    right: 0;
                    left: 0;
                    transform: translate(0) !important;
                    clip-path: none;
                  }
                }
                &.invalid::before {
                  transform: translate(0, -50%);
                  opacity: 1;
                }
                &.invalid {
                  @media (max-width: 768px) {
                    margin-bottom: 24px;
                  }
                }
              }
              .form-submit {
                height: 0;
                overflow: hidden;
              }
            }
            &.sent {
              .form-confirmation {
                visibility: visible;
                clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
              }
            }
          }

          .submit {
            margin-top: 30px;
            padding-right: 27px;
            padding-bottom: 30px;
            padding-left: 27px;

            button {
              color: $blue;
              font-size: 12px;
              font-weight: 400;
              letter-spacing: 1px;
              text-align: center;
              text-transform: uppercase;
              background-color: #FFD072;
              border: none;
              border-radius: 4px;
              position: relative;
              width: 100%;
              height: 54px;
              cursor: pointer;

              &:hover {
                transition: box-shadow 700ms;
              };
              span {
                text-align: center;
                display: inline-block;
                position: absolute;
                top: 50%;
                right: 0;
                left: 0;
                margin-top: -7px;
                transition: all 300ms;
                transition-property: opacity, transform;
              }
              &:not(.loading):not(.sent) {
                span[role='default'] {
                  transform: translateY(0);
                  opacity: 1;
                }
                span[role='loading'],
                span[role='sent'] {
                  transform: translateY(5px);
                  opacity: 0;
                }
              }
              &.loading {
                span[role='default'],
                span[role='sent'] {
                  opacity: 0;
                }
                span[role='default'] {
                  transform: translateY(-5px);
                }
                span[role='loading'] {
                  transform: translateY(0);
                  opacity: 1;
                }
                span[role='sent'] {
                  transform: translateY(5px);
                }
              }
              &.sent {
                span[role='default'],
                span[role='loading'] {
                  transform: translateY(-5px);
                  opacity: 0;
                }
                span[role='sent'] {
                  transform: translateY(0);
                  opacity: 1;
                }
              }
              &.loading {
                background-color: #00ADFD;
                box-shadow: inset 0 -127px 54px -100px #006CD8, 0 0 50px #006CD8;
                transition: all 500ms ease;
              }
              &.sent {
                background-color: #00D062;
                box-shadow: inset 0 -127px 54px -100px #008E0A, 0 0 50px #008E0A;
                transition: all 500ms ease;

                animation: none;
              }
            }
          }
        }
      }
    }
  }

  #empreendimentos {
    margin-top: -40px;
    position: relative;
    z-index: 3;
    .cards {
      display: flex;
      justify-content: space-between;
      margin-bottom: 50px;
      padding: 0 60px;
      @media(max-width: 1279px) {
        padding: 0;
      }
      @media(max-width: 1023px) {
        flex-wrap: wrap;
      }
      .card {
        background-color: #FFF;
        position: relative;
        width: 300px;
        height: 625px;
        box-shadow: 0px 8px 40px 12px rgba(0, 0, 0, .2);
        transition: all 500ms;
        cursor: pointer;
        @media(max-width: 768px) {
          width: 100%;
          margin-bottom: 50px;
        }
        &:hover {
          transform: scale(1.05);
        }
        .link {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        }
        .image {
          margin-bottom: 20px;
          height: 430px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .emp-logo {
          text-align: center;
        }
        .info {
          color: $blue;
          font-size: 13px;
          font-weight: 600;
          letter-spacing: 0.5px;
          line-height: 120%;
          text-align: center;
          text-transform: uppercase;
          border-bottom: 1px solid $blue;
          width: 80%;
          margin: 0 auto;
          padding: 15px;
        }
        .bairro {
          color: $blue;
          font-size: 14px;
          text-align: center;
          text-transform: uppercase;
          padding-top: 15px;
        }
      }
    }
  }

  #info {
    @media(max-width: 768px) {
      background-color: $blue;
      margin-bottom: 50px;
      padding: 40px 0;
    }
    .cta {
      background-image: url('../img/cta.png');
      background-repeat: no-repeat;
      position: relative;
      width: 100%;
      height: 292px;
      margin-bottom: 50px;
      margin-left: 60px;

      @media(max-width: 1279px) {
        margin-left: 5px;
      }
      @media(max-width: 768px) {
        background-image: none;
        margin-left: 0;
      }
      .block {
        border: 3px solid #FFF;
        position: absolute;
        top: 110px;
        left: 360px;
        width: 275px;
        height: 150px;

        // Mobile
        @media(max-width: 1279px) {
          left: 340px;
        }
        @media(max-width: 767px) {
          position: relative;
          top: 0;
          left: 0;
        }
        @media (max-width: 767px) {
          margin: 0 auto 50px;
          left: 5px;
        }
        &.block-2 {
          left: 675px;
          @media(max-width: 1279px) {
            left: 650px;
          }
          @media(max-width: 768px) {
            left: 0;
          }
          // Mobile
          @media (max-width: 767px) {
            left: 5px;
          }
        }
        .icon {
          background-color: $blue;
          display: flex;
          position: absolute;
          top: 22px;
          left: -37px;
          align-items: center;
          justify-content: center;
          width: 80px;
          height: 95px;
        }
        .content {
          padding-top: 40px;
          padding-left: 50px;
          .title {
            color: #FFD072;
            font-size: 29px;
            font-weight: 100;
            letter-spacing: 2px;
            text-transform: uppercase;
            margin-bottom: 10px;
          }
          .number {
            color: #FFF;
            .ddd {
              font-weight: 100;
              padding-right: 5px;
            }

            .big {
              font-size: 28px;
              font-weight: 400;
            }
          }
        }
      }
    }
  }
}
